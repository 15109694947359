<template>
  <div class="history-wrapper">
    <!-- <h1>历史游戏记录</h1> -->
    <el-table :data="tableData" v-if="tableData.length" height="416">
      <el-table-column prop="img" align="center" width="180">
        <template slot-scope="scope">
          <img :src="`${scope.row.Img}?x-oss-process=image/resize,h_52`" alt="" width="92px" height="50px" />
        </template>
      </el-table-column>
      <el-table-column prop="GameName" label="游戏名称" width="180">
      </el-table-column>
      <el-table-column prop="FinalTime" label="最后游戏时间" width="180">
      </el-table-column>
      <el-table-column prop="HasArchive" label="是否有存档">
        <template slot-scope="scope">
          <!-- {{ scope.row.HasArchive ? "查看存档" : "没有存档" }} -->
          <div class="archives">
            <router-link :to="`/userCenter/myItems?GameID=${scope.row.GameID}`" v-if="scope.row.HasArchive">查看存档</router-link>
            <span v-else>没有存档</span>
            <span v-if="scope.row.HasArchive && scope.row.GameID == runGameId">
              <el-button size="mini" @click="backups(scope.row.GameID)">备份存档</el-button>
              <el-tooltip class="item" effect="dark" content="备份之后，存档文件会保存到特权云端，后续更换电脑也可以使用该存档" placement="top">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small" class="btn">登录游戏</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-box" v-if="tableData.length">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
        :page-size="rows" layout="total, prev, pager, next, jumper" :total="Total"></el-pagination>
    </div>

    <div class="no-data" v-if="tableData.length == 0">
      <img src="@/assets/img/nocoll.png" alt="" />
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
import { GetHistorGameProductList } from "@/api";
import { mapGetters } from "vuex";
import cMethods from "@/util/cMethods.js";

export default {
  name: "History",
  computed: {
    ...mapGetters(["isLogin", "userInfo"]),
  },
  data() {
    return {
      rows: 5,
      page: 1,
      Total: 0,
      tableData: [],
      runGameId: null,
      steamId: null,
    };
  },
  mounted() {
    if (this.userInfo) {
      this._GetHistorGameProductList();
    }
    if (sessionStorage.getItem("runningGame")) {
      let obj = JSON.parse(sessionStorage.getItem("runningGame"));
      this.runGameId = obj.GameID;
      this.steamId = obj.steamgameid;
    }
  },
  methods: {
    // 备份存档
    backups(gameId) {
      if (this.isLogin && this.userInfo) {
        cMethods.Form_jsUploadArchiveForTQ(
          this.userInfo.ConsumerId,
          gameId,
          this.steamId
        );
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    async _GetHistorGameProductList() {
      let params = {
        vipId: this.userInfo.ConsumerId,
        rows: this.rows,
        page: this.page,
      };
      let res = await GetHistorGameProductList(params);
      if (res.StatusCode == 200) {
        let data = JSON.parse(res.Object);
        this.tableData = data.Data;
        this.Total = data.Pager && data.Pager.Total;
      }
    },
    // 一页展示多少条
    handleSizeChange(val) {
      this.page = 1;
      this.rows = val;
      this._GetHistorGameProductList();
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this._GetHistorGameProductList();
    },
    handleClick(item) {
      this.$router.push(`/gameDetail/${item.ProductID}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.history-wrapper {
  width: 100%;
  // padding: 15px;

  // h1 {
  //   margin-bottom: 20px;
  //   font-size: 24px;
  //   @include font_col3();
  // }
  .archives {
    color: $red-col;

    a {
      @include font_col3();
      text-decoration: underline;
    }

    .el-button {
      color: $red-col;
      background: transparent;
      border: 1px solid $red-col;
      margin-left: 10px;
    }

    .el-icon-question {
      @include font_col3();
      margin-left: 10px;
    }
  }

  .btn {
    width: 80px;
    // color: $white-col;
    // background: $red-col;
    color: $red-col;
    background: transparent;
    border: 1px solid $red-col;

    &:hover {
      color: $white-col;
      background: $red-col;
    }
  }

  .pagination-box {
    margin: 20px 0;
    text-align: center;
  }

  .no-data {
    min-height: 466px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 10px;
      font-size: 16px;
      @include font_col3();
    }
  }
}
</style>
